import apiClient from "@/api/api-client";

export const getBatchesList = async (filters) => {
  const { data } = await apiClient.get("/batches", {
    params: filters,
  });
  console.log(data);
  return data;
};

export const changeBatchesList = async (batch_id, is_active) => {
  const { data } = await apiClient.post("/batches", { batch_id, is_active });
  console.log(data);
  return data;
};
