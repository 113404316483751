<template>
  <header class="flex items-center justify-between p-6 border-b">
    <div class="mr-10">
      <h1 class="text-3xl font-semibold">
        {{ pageTitle }} <i v-if="loading" class="el-icon-loading"></i>
      </h1>
      <div class="mt-1 text-sm font-light text-gray-700">
        {{ totalFiles }} шт
      </div>
    </div>
    <div class="flex flex-wrap gap-2">
      <el-select
        v-model="filters.batch_id"
        v-if="payedState"
        placeholder="Партия файлов"
        size="large"
        style="width: 240px"
        @change="handleChangeFilters"
        clearable
      >
        <el-option
          v-for="batch in batches"
          :key="batch._id"
          :value="batch._id"
          :label="batch.name"
        >
          <template #default>
            <div class="w-full flex items-center justify-between">
              <span class="text-medium text-gray-600">{{
                batch.batch_name || "-"
              }}</span>
              <span
                class="ml-3 h-2.5 w-2.5 rounded-full"
                :class="batch.is_active ? 'bg-green-500' : 'bg-gray-400'"
                style="font-size: 12.5px"
              >
              </span>
            </div>
          </template>
        </el-option>
      </el-select>
      <el-select
        v-if="payedPage"
        v-model="filters.action"
        @change="getFiles"
        style="width: 240px"
        size="large"
      >
        <el-option value="" label="По дате изменения"></el-option>
        <el-option value="payed_date" label="По дате оплаты"></el-option>
      </el-select>
      <el-date-picker
        value-format="YYYY-MM-DD"
        @change="handleChangeFilters"
        v-model="filters.date"
        placeholder="Выберите отрезок времени"
        type="daterange"
        style="width: 240px"
        size="large"
      ></el-date-picker>
      <el-select
        v-if="showDownloadButton"
        v-model="filters.annotator"
        placeholder="Аннотатор"
        @change="handleChangeFilters"
        clearable
        filterable
        size="large"
        style="width: 240px"
      >
        <el-option
          v-for="user in users"
          :key="user._id"
          :value="user._id"
          :label="`${user.first_name} ${user.last_name}`"
        ></el-option>
      </el-select>

      <el-select
        v-model="filters.lang"
        placeholder="Язык"
        @change="handleChangeFilters"
        clearable
        size="large"
        style="width: 240px"
      >
        <el-option value="kz" label="Қаз"></el-option>
        <el-option value="ru" label="Рус"></el-option>
      </el-select>

      <el-select
        v-model="filters.is_model"
        placeholder="Для модели"
        @change="handleChangeFilters"
        clearable
        size="large"
        style="width: 240px"
      >
        <el-option :value="null" label="Все"></el-option>
        <el-option value="1" label="Для модели"></el-option>
        <el-option value="0" label="Не для модели"></el-option>
      </el-select>

      <el-button
        v-if="showDownloadButton"
        @click="download"
        :disabled="downloading"
        :icon="downloading ? 'el-icon-loading' : ''"
        type="success"
        size="large"
        plain
        >Скачать xlsx</el-button
      >
    </div>
  </header>

  <main class="p-6 pt-1">
    <div
      class="mb-3 mt-1 flex justify-between"
      v-if="user.scopes.includes('admin') || user.scopes.includes('verifier')"
    >
      <div
        class="px-3 py-5 border border-solid border-gray-200 rounded-xl mr-3 flex-grow"
        style="min-width: 200px"
      >
        <p class="text-lg font-medium">
          {{ timeNormalizer(analytics.seconds) }}
        </p>
        <p class="text-sm text-gray-400 uppercase">Общее время(Размеченных)</p>
      </div>
      <!--      <div-->
      <!--        class="px-3 py-5 border border-solid border-gray-200 rounded-xl mr-3 flex-grow"-->
      <!--        style="min-width: 200px"-->
      <!--      >-->
      <!--        <p class="text-lg font-medium">-->
      <!--          {{ timeNormalizer(analytics.seconds_verifier) }}-->
      <!--        </p>-->
      <!--        <p class="text-sm text-gray-400 uppercase">-->
      <!--          Общее время(Верифицированных)-->
      <!--        </p>-->
      <!--      </div>-->
      <div
        class="px-3 py-5 border border-solid border-gray-200 rounded-xl mr-3 flex-grow"
        style="min-width: 200px"
      >
        <p class="text-lg font-medium">
          {{ numberFormat(analytics.summ_ann) }}
        </p>
        <p class="text-sm text-gray-400 uppercase">
          Деньги за размеченные(Разметчик)
        </p>
      </div>
      <div
        class="px-3 py-5 border border-solid border-gray-200 rounded-xl mr-3 flex-grow"
        style="min-width: 200px"
      >
        <p class="text-lg font-medium">{{ numberFormat(analytics.summ_vf) }}</p>
        <p class="text-sm text-gray-400 uppercase">
          Деньги за размеченные(Верификатор)
        </p>
      </div>
    </div>
    <div class="justify-end items-center flex">
      <el-button
        @click="importDrawer = true"
        v-if="state === 'all'"
        type="primary"
        plain
        >Импорт новых аудиозаписей</el-button
      >
      <el-button
        v-if="payedState && user.scopes.includes('admin')"
        type="success"
        plain
        @click="payForAll"
        >Оплатить все за этот период</el-button
      >
      <el-button
        @click="payDrawer = !payDrawer"
        v-if="selectedItems.length && user.scopes.includes('admin')"
        type="success"
        >Оплатить выбранные файлы</el-button
      >
      <el-button
        v-if="payedPage && userData && userData.batch_access"
        type="primary"
        plain
        @click="SendtoMarkUp"
        >Отправить в разметку</el-button
      >
      <el-select
        v-model="filters.sort"
        @change="handleChangeFilters"
        class="my-2 ml-2"
        style="width: 240px"
      >
        <el-option
          v-for="item in sortList"
          :key="item.key"
          :value="item.key"
          :label="item.title"
        ></el-option>
      </el-select>
    </div>
    <div class="h-full">
      <el-table
        border
        class="h-full"
        :data="files"
        style="height: 100%"
        :row-class-name="tableRowClassName"
        @selection-change="handleSelection"
        ref="multiselection"
      >
        <el-table-column
          v-if="!payedState"
          type="index"
          label="N"
          align="center"
          width="55"
        ></el-table-column>
        <el-table-column v-else type="selection" width="55"></el-table-column>
        <el-table-column
          type="index"
          label="Имя файла"
          v-if="user.scopes.includes('annotator')"
          min-width="500"
        >
          <template #default="scope">
            {{ scope.row._id }}
          </template>
        </el-table-column>
        <el-table-column label="Длительность" width="150">
          <template #default="scope">
            <div v-if="scope.row.duration">
              {{ timeNormalizer(scope.row.duration.mix) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Время верифицировано"
          :min-width="220"
          v-if="!user.scopes.includes('annotator')"
        >
          <template v-slot="scope">
            {{ timeNormalizer(scope.row.seconds || 0) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="lang"
          :width="80"
          align="center"
          label="Язык"
        ></el-table-column>
        <el-table-column
          v-if="
            payedState &&
            (user.scopes.includes('admin') || user.scopes.includes('verifier'))
          "
          label="Действие"
          width="180px"
          align="center"
        >
          <template v-slot="scope">
            <el-switch
              v-model="scope.row.is_model"
              @change="() => tagForModel(scope.row._id, scope.row.is_model)"
              inline-prompt
              style="
                --el-switch-on-color: #13ce66;
                --el-switch-off-color: #ff4949;
              "
              :active-value="1"
              :inactive-value="0"
              active-text="Для модели"
          /></template>
        </el-table-column>
        <el-table-column
          prop="datetime"
          label="Последнее изменение"
          align="center"
          :width="220"
        >
          <template #default="scope">
            <span>
              {{ dateFormat(scope.row.updated_at) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column v-if="payedPage" label="Дата оплаты" :min-width="180">
          <template #default="scope">
            {{
              scope.row.payment_info && dateFormat(scope.row.payment_info.date)
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="annotator"
          label="Статус"
          align="center"
          min-width="180"
          v-if="!user.scopes.includes('annotator')"
        >
          <template #default="scope">
            <span
              class="bg-gray-200 rounded px-1 text-xs"
              :class="{
                'bg-yellow-500 text-white': scope.row.status === 1,
                'bg-blue-500 text-white': scope.row.status === 2,
                'bg-green-500 text-white': scope.row.status === 3,
                'bg-indigo-500 text-white': scope.row.status === 4,
              }"
            >
              {{ getStatusTitle(scope.row.status) }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="annotator"
          label="Аннотатор"
          :min-width="250"
          v-if="!user.scopes.includes('annotator')"
        >
          <template #default="scope">
            <div v-if="scope.row.annotator">
              {{ scope.row.annotator.first_name }}
              {{ scope.row.annotator.last_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="verifier"
          label="Верификатор"
          :min-width="250"
          fixed="right"
          v-if="!user.scopes.includes('annotator')"
        >
          <template #default="scope">
            <div v-if="scope.row.verifier">
              {{ scope.row.verifier.first_name }}
              {{ scope.row.verifier.last_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" align="center" fixed="right">
          <template #default="scope">
            <el-button @click="() => viewFile(scope.row)" type="primary" plain>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style="height: 12px; width: 12px"
                viewBox="0 0 24 24"
                fill="transparent"
              >
                <path
                  d="M4 22H20"
                  stroke="currentColor"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
                <path
                  d="M13.8881 3.66293L14.6296 2.92142C15.8581 1.69286 17.85 1.69286 19.0786 2.92142C20.3071 4.14999 20.3071 6.14188 19.0786 7.37044L18.3371 8.11195M13.8881 3.66293C13.8881 3.66293 13.9807 5.23862 15.3711 6.62894C16.7614 8.01926 18.3371 8.11195 18.3371 8.11195M13.8881 3.66293L7.07106 10.4799C6.60933 10.9416 6.37846 11.1725 6.17992 11.4271C5.94571 11.7273 5.74491 12.0522 5.58107 12.396C5.44219 12.6874 5.33894 12.9972 5.13245 13.6167L4.25745 16.2417M18.3371 8.11195L11.5201 14.9289C11.0584 15.3907 10.8275 15.6215 10.5729 15.8201C10.2727 16.0543 9.94775 16.2551 9.60398 16.4189C9.31256 16.5578 9.00282 16.6611 8.38334 16.8675L5.75834 17.7426M5.75834 17.7426L5.11667 17.9564C4.81182 18.0581 4.47573 17.9787 4.2485 17.7515C4.02128 17.5243 3.94194 17.1882 4.04356 16.8833L4.25745 16.2417M5.75834 17.7426L4.25745 16.2417"
                  stroke="currentColor"
                  stroke-width="1.5"
                />
              </svg>
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="mt-3 flex justify-center">
        <el-pagination
          v-if="totalFiles"
          background
          layout="pager"
          :total="totalFiles"
          :default-current-page="defaultPage"
          :current-page="filters.page"
          @update:current-page="handleChangePage"
          :page-size="15"
        />
      </div>
    </div>
  </main>
  <el-dialog v-model="payDrawer" title="Выберите дату">
    <el-date-picker
      v-model="formPay.date"
      type="date"
      value-format="YYYY-MM-DD"
    ></el-date-picker>
    <div class="text-right mt-5">
      <el-button :disabled="!formPay.date" @click="payFileList"
        >Оплатить</el-button
      >
    </div>
  </el-dialog>
  <el-dialog
    v-model="importDrawer"
    top="30px"
    width="70%"
    @closed="clearImportForm"
    title="Импорт новых аудиозаписей"
  >
    <el-form
      ref="import-form"
      label-width="400px"
      :model="importForm"
      :rules="importFormRules"
    >
      <el-form-item label="Выберите темы загружаемых файлов" prop="topic">
        <el-select v-model="importForm.topic" style="width: 240px" size="large">
          <el-option :value="null" label="Все"></el-option>
          <el-option
            v-for="topic in topics"
            :key="topic.key"
            :value="topic.key"
            :label="topic.title"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Выберите язык загружаемых файлов" prop="lang">
        <el-select v-model="importForm.lang" style="width: 240px" size="large">
          <el-option :value="null" label="Все"></el-option>
          <el-option value="ru" label="Русский язык"></el-option>
          <el-option value="kz" label="Казахский язык"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Количество файлов для предпросматра" prop="limit">
        <el-input
          type="number"
          max="100"
          min="0"
          v-model="importForm.limit"
          size="large"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="text-right">
      <el-button type="primary" plain @click="getForImportFiles"
        >Получить список файлов</el-button
      >
    </div>
    <div v-loading="loadingImportFiles">
      <el-table
        scrollbar-always-on
        height="400px"
        :data="importFiles"
        header-row-class-name="sticky"
        @selection-change="handleSelectionImport"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="datetime" label="Дата и время">
          <template #default="scope">
            {{ dateFormat(scope.row.datetime) }}
          </template>
        </el-table-column>
        <el-table-column label="Топик">
          <template #default="scope">
            {{ getNameOfTopic(scope.row.topic) }}
          </template>
        </el-table-column>
        <el-table-column prop="lang" label="Язык"></el-table-column>
        <el-table-column label="Продолжительность">
          <template #default="scope">
            <p>Оператор: {{ timeNormalizer(scope.row.duration.op) }}</p>
            <p>Клиент: {{ timeNormalizer(scope.row.duration.user) }}</p>
            <p>Микс: {{ timeNormalizer(scope.row.duration.mix) }}</p>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div
      class="text-right mt-5"
      v-if="selectedItemsImport && selectedItemsImport.length"
    >
      <el-button
        icon="el-icon-download"
        type="success"
        plain
        @click="importNewFiles"
        >Загрузить на платформу</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { getSection } from "./shared/sections";
import {
  exportToExcel,
  getFiles,
  getNewFiles,
  getPreviewFiles,
  tagForModel,
} from "@/api/files";
import { getStatus as _getStatus } from "./shared/statuses";
import { format, intervalToDuration } from "date-fns";
import { getUser as _gertUser, getUsers } from "@/api/users";
import { getBatchesList } from "@/api/batches";
import apiClient from "@/api/api-client";

export default {
  props: {
    state: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      downloading: false,
      files: [],
      users: [],
      userData: [],
      batches: [],
      totalFiles: 0,
      topics: [
        { key: "egov", title: "Egov" },
        { key: "tson", title: "ЦОН" },
        { key: "sp", title: "По пенсионным вопросам" },
        { key: "ashyq", title: "Ashyq" },
        { key: "sl", title: "По трудом вопросам" },
      ],
      importForm: {
        topic: null,
        lang: null,
        limit: null,
      },
      loadingImportFiles: false,
      importFormRules: {
        limit: [
          {
            required: true,
            message: "Это обязательное поле",
            trigger: ["blur", "change"],
          },
          {
            max: 100,
            min: 1,
            message: "Количество файлов должна быть меньше 100",
            trigger: ["blur", "change"],
          },
        ],
      },
      filters: {
        page: 1,
        status: undefined,
        annotator: undefined,
        lang: null,
        is_model: null,
        batch_id: null,
        date: [],
        action: "",
        sort: "updated_at:desc",
      },
      importDrawer: false,
      importFiles: [],
      selectedItems: [],
      selectedItemsImport: [],
      formPay: {
        selectedFiles: [],
        date: "",
      },
      payDrawer: false,
      analytics: {},
      sortList: [
        {
          key: "datetime:desc",
          title: "По созданию файла(по уменьшению)",
        },
        {
          key: "datetime:asc",
          title: "По созданию файла(по увеличению)",
        },
        {
          key: "updated_at:desc",
          title: "По изменению файла(по уменьшению)",
        },
        {
          key: "updated_at:asc",
          title: "По изменению файла(по увеличению)",
        },
        {
          key: "duration.mix:desc",
          title: "По длительности файла(по уменьшению)",
        },
        {
          key: "duration.mix:asc",
          title: "По длительности файла(по увеличению)",
        },
      ],
    };
  },
  computed: {
    sidebarItem() {
      return getSection(this.state === "all" ? this.state : +this.state);
    },
    pageTitle() {
      return this.sidebarItem.title;
    },
    getNameOfTopic() {
      return (topic) => {
        let topicObj = this.topics.find((k) => k.key === topic);
        if (topicObj) {
          return topicObj.title;
        }
        return "Не указано";
      };
    },
    fileId() {
      return this.$route.query.fileId;
    },
    payedState() {
      if (this.state === "all") return false;
      return +this.state === 4;
    },
    payedPage() {
      if (this.state === "all") return false;
      return +this.state === 5;
    },
    defaultPage() {
      return +(this.$route.query.page || 1);
    },
    user() {
      return this.$store.state.user.user;
    },
    showDownloadButton() {
      return (
        this.user.scopes.includes("admin") ||
        this.user.scopes.includes("verifier")
      );
    },
  },
  async mounted() {
    if (this.state !== "all") {
      this.filters.status = +this.state;
    }
    this.filters.page = this.defaultPage;
    await this.getUser();
    await this.getFiles();
    await this.getBatches();
    this.users = await getUsers();
  },
  watch: {
    async state() {
      if (this.state !== "all") {
        this.filters.status = +this.state;
      } else {
        this.filters.status = undefined;
      }
      this.filters.lang = null;
      this.filters.page = 1;
      this.filters.batch_id = null;
      await this.getFiles();
    },
  },
  methods: {
    async getUser() {
      this.loading = true;
      const user = await _gertUser(this.user._id);
      this.loading = false;
      this.userData = user || [];
    },
    async getBatches() {
      try {
        const { data } = await getBatchesList();
        this.batches = data || [];
      } catch (error) {
        console.log(error);
      }
    },
    showPayForm() {
      this.payDrawer = !this.payDrawer;
    },
    async handleChangePage(val) {
      console.log("CHANGE PAGE", val);
      this.filters.page = val;
      this.$router.push({ query: { page: val } });
      await this.getFiles();
    },
    tableRowClassName({ row }) {
      if (this.fileId === row._id) {
        return "bg-blue-50";
      }
      return "";
    },
    async SendtoMarkUp() {
      try {
        const json = {}; // Пустой JSON-объект
        const response = await apiClient.post("/files", json, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Успешный ответ:", response.data);
      } catch (error) {
        console.error("Ошибка при отправке запроса:", error);
      }
    },
    async getFiles() {
      this.loading = true;
      let filters = this.getFilters();

      const data = await getFiles(filters);
      this.analytics = data.analytics;
      this.files = [...data.data];
      this.files = this.files.map((k) => {
        if (!("is_model" in k)) {
          return { ...k, is_model: 0 };
        }
        return k;
      });
      this.totalFiles = data.page.total_items;
      this.loading = false;
    },
    async handleChangeFilters() {
      console.log("CHANGE FILTERS");
      this.filters.page = 1;
      await this.getFiles();
    },
    viewFile(row) {
      if (row.one_audio) {
        this.$router.push({
          name: "file-view-full-audio",
          params: { state: row.status, fileId: row._id },
        });
      } else {
        this.$router.push({
          name: "file-view",
          params: { state: row.status, fileId: row._id },
        });
      }
    },
    timeNormalizer(timeInSeconds) {
      let pad = function (num, size) {
        return ("000" + num).slice(size * -1);
      };

      let days = Math.floor((timeInSeconds % 31536000) / 86400);
      let hours = Math.floor(((timeInSeconds % 31536000) % 86400) / 3600);
      let minutes = Math.floor(
        (((timeInSeconds % 31536000) % 86400) % 3600) / 60
      );
      let seconds = Math.floor(
        (((timeInSeconds % 31536000) % 86400) % 3600) % 60
      );

      let totalHours = days * 24 + hours;

      if (timeInSeconds > 3600) {
        return (
          totalHours + "ч:" + pad(minutes, 2) + "мин:" + pad(seconds, 2) + "сек"
        );
      }
      return pad(minutes, 2) + ":" + pad(seconds, 2);
    },
    getStatusTitle(state) {
      const status = _getStatus(state);
      return (status && status.title) || state;
    },
    dateFormat(date) {
      if (!date) return "";
      return format(new Date(date), "dd MMM yyyy, HH:mm");
    },
    secondsToTime(time) {
      let duration = intervalToDuration({ start: 0, end: (time || 0) * 1000 });
      return `${duration.hours}:${duration.minutes}:${duration.seconds}`;
    },
    async download() {
      this.downloading = true;
      let params = {};
      if (this.filters.date && this.filters.date.length > 1) {
        params.start_date = this.filters.date[0];
        params.stop_date = this.filters.date[1];
      }
      await exportToExcel({
        status: +this.state,
        annotator: this.filters.annotator,
        ...params,
      });
      this.downloading = false;
    },
    handleSelection(val) {
      console.log(val);
      this.selectedItems = val.map((k) => k._id);
    },
    numberFormat(number) {
      return number && new Intl.NumberFormat().format(number);
    },
    payForAll() {
      this.payDrawer = true;
      this.filters.action = "all_files";
    },
    getFilters() {
      let params = { ...this.filters };
      if (this.filters.date && this.filters.date.length > 1) {
        params.start_date = this.filters.date[0];
        params.stop_date = this.filters.date[1];
      }
      delete params.action;
      if (this.filters.action) {
        params.action = this.filters.action;
      }
      if (this.filters.batch_id) {
        params.batch_id = this.filters.batch_id;
      }
      if (this.filters.is_model) {
        params.is_model = this.filters.is_model;
      }
      delete params.date;
      return params;
    },
    async getForImportFiles() {
      let validation = await this.$refs["import-form"].validate();
      if (validation) {
        let params = { ...this.importForm };
        this.loadingImportFiles = true;
        let data = await getPreviewFiles(params);
        this.loadingImportFiles = false;
        console.log(data);
        this.importFiles = data.data;
      }
    },
    async importNewFiles() {
      let files_ids = this.selectedItemsImport.map((k) => k.id);
      let data = await getNewFiles(files_ids);
      if (data._success) {
        console.log(files_ids);
        this.clearImportForm();
        this.getFiles();
      }
    },
    clearImportForm() {
      this.importForm.topic = null;
      this.importForm.lang = null;
      this.importForm.limit = null;
      this.importFiles = [];
      this.selectedItemsImport = [];
      this.importDrawer = false;
    },
    async handleSelectionImport(val) {
      this.selectedItemsImport = val;
    },
    async tagForModel(file_id, state) {
      let action = "unmarked";
      if (state) {
        action = "marked";
      }
      let data = tagForModel(file_id, action);
      console.log(data);
    },
  },
};
</script>

<style>
.el-table .bg-blue-50 {
  background: rgba(239, 246, 255, 1);
}
</style>
