import { statuses } from "./statuses";

const sectionsList = [
  {
    scopes: ["admin"],
    to: { name: "files-list", params: { state: "all" } },
  },
  {
    scopes: ["admin", "annotator"],
    to: { name: "files-list", params: { state: 0 } },
  },
  {
    scopes: ["admin", "annotator"],
    to: { name: "files-list", params: { state: 1 } },
  },
  {
    scopes: ["admin", "annotator", "verifier"],
    to: { name: "files-list", params: { state: 2 } },
  },
  {
    scopes: ["admin", "annotator", "verifier"],
    to: { name: "files-list", params: { state: 3 } },
  },
  {
    scopes: ["admin", "annotator", "verifier"],
    to: { name: "files-list", params: { state: 4 } },
  },
  {
    scopes: ["admin", "annotator"],
    to: { name: "files-list", params: { state: 5 } },
  },
  {
    scopes: ["admin"],
    to: { name: "files-list", params: { state: 6 } },
  },
].map((item) => {
  const status = statuses[item.to.params.state];
  return {
    ...item,
    title: status.pluralTitle,
  };
});

export function getSections(scope) {
  return sectionsList.filter((item) => item.scopes.includes(scope));
}

export function getSection(state) {
  return sectionsList.find((si) => si.to.params.state === state);
}
